.testimonial_container{
    width: 750px;

}


.testimonial_card{
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
    margin-bottom:var(--mb-3) ;


}

.testimonial_img{
    width: 60px;
    border-radius: 3rem;
    margin-bottom: var(--mb-3);

}


.testimonial_name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mg-0-25);
}

.testimmonial_description{
    font-size: var(--small-font-size);

}


/*Swiper Class*/
.swiper-pagination-bullet{
    background-color: var(--text-color) !important;
    
}

.swiper-pagination-bullet-active{
    background-color: var(--text-color) !important;
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .testimonial_container{
        width: initial ;

    }

    .testimonial_card{
        padding: 1.25  rem 1.5rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {

  }
  
  @media screen and (max-width: 576px) {
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
  
  }
  
/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .container {
      margin-left: var(--mb-1-5);
      margin-right: var(--mb-1-5);
    }
  
    .button {
      padding: 1rem 1.75rem;
    }
  
    .button__icon {
      width: 22px;
      height: 22px;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    body {
      margin: 0 0 var(--header-height) 0;
    }
  
    .section {
      padding: 2rem 0 4rem;
    }
  
    .section__subtitle {
      margin-bottom: var(--mb-3);
    }
  }
  
  @media screen and (max-width: 576px) {
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    :root {
      --big-font-size: 2.25rem;
    }
  
    .container {
      margin-left: var(--mb-1);
      margin-right: var(--mb-1);
    }
  }
  